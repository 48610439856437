import React from 'react';
import * as DocumentsBase from '../Find/DocumentsBase';
import {IDocumentNavigationItem,IDocumentNavigatorFilter} from '../DocumentNavigator/DocumentNavigatorItem';
import DocumentNavigatorTagCrumbs from '../DocumentNavigator/DocumentNavigatorTagCrumbs';
import DocumentNavigatorMenu from '../DocumentNavigator/DocumentNavigatorMenu';
import SearchDocumentList from './SearchDocumentList';
import SearchDocumentGrid from './SearchDocumentGrid';

export class SearchNavigableDocumentItem extends DocumentsBase.DocumentItem {
    marketReportName:string=null;
    marketReportPublished:Date=null;
    marketReportType:string=null;
    marketReportKind:string=null;
}

export interface ISearchDocumentNavigatorProps extends DocumentsBase.IDocumentsProps<SearchNavigableDocumentItem> {
    marketReportKind?:string;
    initialFilters:Array<IDocumentNavigatorFilter>;
	documentNavigation:IDocumentNavigationItem;
    enableFollowDocuments: boolean;
    openDocumentsInNewTarget: boolean;
}

export interface ISearchDocumentNavigatorState extends DocumentsBase.IDocumentsState<SearchNavigableDocumentItem> {
    query:SearchDocumentNavigatorQuery;
    navigating:boolean;
    navigationTrail:Array<{MenuKey:string,MenuValue:string}>;
    pageSize: number;
    page: number;
    currentNavigationPath:Array<IDocumentNavigationItem>;
    currentNavigationItem:IDocumentNavigationItem;
    currentNavigationValues:Array<string>;
    readersGuide:SearchNavigableDocumentItem;
    sort: string;
    sortDirection: string;
}

export class SearchDocumentNavigatorQuery {
    from:number;
    size:number;
	sort: Array<any> = 
		[ 
			{ "FileName": {"order":"asc"} },
			{ "Name":"asc" },  
			"_score" 
        ];
    filter: {
        and:Array<any>
    } = {
        and:[]
    };
    facets = { 
    };
	query: {
		filtered:{
			filter: { 
				and:Array<any>
			},
			query: {
				query_string: { query:string }
			}
		}
	} = {
		filtered: {
			filter: {
			and: []
		},
		query: {
			query_string: { query: "*" } 
		}
	}
   };

	public constructor() { 
	}
}

const defaultPageSize = 10;

export class SearchDocumentNavigator extends DocumentsBase.DocumentsBase<SearchNavigableDocumentItem, ISearchDocumentNavigatorProps, ISearchDocumentNavigatorState> {
    private static hashToStateMap = [
        { hashParam: "t", stateParam: "pageSize"},
        { hashParam: "p", stateParam: "page"},
        { hashParam: "s", stateParam: "sort"},
        { hashParam: "sd", stateParam: "sortDirection"},
        //{ hashParam: "f", stateParam: "filters"},
    ];

    protected get placeholderTemplate() {
        if (!this.state.currentNavigationItem.childDocumentNavigationItems || !this.state.currentNavigationItem.childDocumentNavigationItems.length) {
            return "Sorry, no results were found that match your query.";
        }
        else {
            return `In order to view documents related to ${this.state.currentNavigationItem.title || this.state.currentNavigationItem.parentPropertyValue}, continue using the navigation on the left`;
        }
    }
    
    constructor(props) {
		super(props);
		this.state = { results: [], pageSize: defaultPageSize, page: 0, sort: null, sortDirection: null, navigationTrail: [], currentNavigationPath: [], currentNavigationItem: null, currentNavigationValues:[], totalAvailableResults: 0, query: null, loading: true, navigating: false, readersGuide: null };
        this.clickedNavigationItemCrumb = this.clickedNavigationItemCrumb.bind(this);
        if (typeof window !== "undefined") {
            window.addEventListener("hashchange", this.handlePop.bind(this));
        }
    };



    componentWillUnmount() {
        //TODO
        removeEventListener("hashchange", this.handlePop.bind(this));
    }

    private _hashParams:any;
    get hashParams():any {
        if (!this._hashParams && location.hash && location.hash.length > 1) {
          this._hashParams = this.parseParams(location.hash.substr(1));
        } 

        return this._hashParams;
    }

    private oldURL:string;
    public handlePop(event: HashChangeEvent){
      let oldURL = event.oldURL || this.oldURL;
      let newUrl = event.newURL || location.href;
      this.oldURL = location.href;

      if (oldURL != newUrl) {
          this._hashParams = null;

          if (this.hashParams && this.hashParams.nt)  {
            let navTrail = this.parseNavigationPath(this.hashParams.nt);

            if (navTrail && navTrail.length && navTrail != this.state.navigationTrail && 
                this.doEndsOfNavigationTrailsDiffer(navTrail,this.state.navigationTrail )) {
                if (navTrail.length > this.state.navigationTrail.length) {                    
                    this.SetNextNavigationItemStateFromNavTrail(navTrail, this.state.currentNavigationItem);

                } else if (navTrail.length == this.state.navigationTrail.length) {
                    let previousNavigationItem = this.state.currentNavigationPath[this.state.currentNavigationPath.length - 2]
                    
                    this.SetNextNavigationItemStateFromNavTrail(navTrail, previousNavigationItem);
                }
            }
        }
      }
    }

    private doEndsOfNavigationTrailsDiffer(navTrail1:Array<{MenuKey:string,MenuValue:string}>,navTrail2:Array<{MenuKey:string,MenuValue:string}>) {
        if (!navTrail1 || !navTrail2 ) {
            return false;
        } else {
            if (navTrail1.length != navTrail2.length) {
                return true;
            }

            let lastNavTrailItem1 = navTrail1[navTrail1.length - 1];
            let lastNavTrailItem2 = navTrail2[navTrail2.length - 1];

            return (lastNavTrailItem1.MenuKey != lastNavTrailItem2.MenuKey || lastNavTrailItem1.MenuValue != lastNavTrailItem2.MenuValue )
        }
    }

    private SetNextNavigationItemStateFromNavTrail(navTrail:Array<{MenuKey:string,MenuValue:string}>, navigatingItem:IDocumentNavigationItem) {
        let partialNavigationItemState = this.GetNextNavigationItemFromNavTrail(navTrail, navigatingItem);

        if (partialNavigationItemState) {
            partialNavigationItemState.page = 0;
            partialNavigationItemState.pageSize = defaultPageSize;
            partialNavigationItemState.sort = null;
            partialNavigationItemState.sortDirection = null;
            partialNavigationItemState.loading = true;
            this.setState(partialNavigationItemState);
        }
    }

    private GetNextNavigationItemFromNavTrail(navTrail:Array<{MenuKey:string,MenuValue:string}>, navigatingItem:IDocumentNavigationItem):any {
        let lastNavTrailItem = navTrail[navTrail.length - 1];

        if (lastNavTrailItem.MenuKey === navigatingItem.childPropertySystemName) {
            let potentialMatchingChildItems = navigatingItem.childDocumentNavigationItems.filter((childItem, i) => {
                return childItem.parentPropertyValue === lastNavTrailItem.MenuValue;
            });

            if (potentialMatchingChildItems && potentialMatchingChildItems.length) {
                let newNavigationItem = potentialMatchingChildItems[0];
                let navigationPath = this.state.currentNavigationPath;

                if (navigationPath[navigationPath.length - 1] != navigatingItem) {
                    navigationPath.splice(-1, 1);
                }
                navigationPath.push(newNavigationItem);

                return {
                    currentNavigationItem: newNavigationItem, 
                    currentNavigationPath: navigationPath,
                    navigationTrail: navTrail,
                    results: [],
                    query: null,
                    readersGuide: null
                };
            }
        }
    }

    public clickedNavigationItemCrumb(index: number) {
        if (index < this.state.currentNavigationPath.length - 1) {
            let navigationPath = this.state.currentNavigationPath;
            navigationPath.splice(index + 1);
            let navTrail = this.state.navigationTrail;
            navTrail.splice(index);
            let newNavigationItem = navigationPath[navigationPath.length - 1];

            this.setState({
                currentNavigationItem: newNavigationItem, 
                currentNavigationPath: navigationPath, 
                navigationTrail: navTrail,
                page: 0, 
                pageSize: defaultPageSize,
                results: [],
                readersGuide: null,
                sort: null,
                sortDirection: null,
                query: null,
                loading: true
            });
        }
    }

    public componentDidMount(){
        super.componentDidMount();   
        if (this.props.documentNavigation) {
            let initialNavigationState = this.props.documentNavigation;
            if (!initialNavigationState.title && ! initialNavigationState.parentPropertyValue) {
                initialNavigationState.title = "Home";
            }
            let currentNavigationPath = [initialNavigationState];
            let navTrail = this.state.navigationTrail || [];

            let initialState = this.getPartialStateFromHash();
            initialState.currentNavigationPath = currentNavigationPath;
            initialState.currentNavigationItem = initialNavigationState;
            initialState.navigationTrail = navTrail;
            if (location.hash && location.hash.indexOf("#") >= 0 && location.hash.indexOf("nt=") >= 0 && this.hashParams.nt) {
  
                initialState.forceLoading = true;
            }

            this.setState(initialState);
        }
    }

    protected postComposeFilters(queryFilters:Array<any>, resultFilters:Array<any>) {

    }

    public componentDidUpdate(prevProps, prevState) {
        if ((this.state.query) && 
            (this.state.query != prevState.query || this.state.page != prevState.page || this.state.pageSize != prevState.pageSize || this.state.sort != prevState.sort || this.state.sortDirection != prevState.sortDirection)) {
            this.ensureUpdatedHash(); 

            if (this.state.query.size != this.state.pageSize || this.state.query.from != (this.state.page * this.state.pageSize)) {
                this.state.query.size = this.state.pageSize;
                this.state.query.from = (this.state.page * this.state.pageSize);
            }

            if (this.state.sort &&
                (!(this.state.sort in this.state.query.sort[0]) || this.state.query.sort[0][this.state.sort].order != this.state.sortDirection)) {
                    let sort = {};
                    sort["Properties." + this.state.sort] = { order:  this.state.sortDirection};
                    this.state.query.sort[0] =  sort  ;
            }

            if (this.state.forceLoading) {
                this.state.query.size = 10;
            }

            if (this.state.query.size < 0) {
                delete this.state.query.size;
            }

            this.executeSearch();
        } else if (this.state.currentNavigationItem != prevState.currentNavigationItem) {
            let query = new SearchDocumentNavigatorQuery();

            if (this.state.currentNavigationItem.childPropertySystemName && this.state.currentNavigationItem.useDynamicChoiceMode === "DynamicChoices" 
                && (!this.state.currentNavigationItem.childDocumentNavigationItems || this.state.currentNavigationItem.childDocumentNavigationItems.length <= 1) ) {
                query.facets = {};
                query.facets[this.state.currentNavigationItem.childPropertySystemName] = {
                    "terms": { "field": "Properties." + this.state.currentNavigationItem.childPropertySystemName, "size": 1000, 
                        "order": this.state.currentNavigationItem.dynamicChoiceSortDirection === "desc" ? "reverse_term" : "term"}
                }
            } else {
                delete query.facets;
            }

            let fieldsToFilter:{[key:string]: string} = {};
            let queryFilters:Array<any> = [];
            let resultFilters:Array<any> = [];
            this.composeChildFilters(fieldsToFilter, this.state.currentNavigationItem);

            if (this.state.navigationTrail && this.state.navigationTrail.length) {
                this.state.navigationTrail.map((navTrailItem, i) => {
                    fieldsToFilter[navTrailItem.MenuKey] = navTrailItem.MenuValue;
                });
            } 

            if (this.props.initialFilters && this.props.initialFilters.length) {
                this.props.initialFilters.map((configuredFilter, i)  => {
                    if (configuredFilter.propertyName) {
                        if (configuredFilter.value) {
                            let term = {};
                            term[configuredFilter.propertyName] = configuredFilter.value;
                            queryFilters.push({query: { term: term  } });
                        } else {
                            queryFilters.push({ exists: {field: "Properties." + configuredFilter.propertyName}});
                        }
                    }
                })
            }

            Object.getOwnPropertyNames(fieldsToFilter).map((fieldFilterName, i) => {
                if (fieldsToFilter[fieldFilterName]) {
                    let term = {};
                    term[fieldFilterName] = fieldsToFilter[fieldFilterName];
                    queryFilters.push({query: { term: term  } });
                } else {
                    //queryFilters.push({ exists: {field: fieldFilterName}});
                    resultFilters.push({not: { exists: {field: fieldFilterName}}});
                }
            })

            this.postComposeFilters(queryFilters, resultFilters);
            
            query.query.filtered.filter.and = queryFilters;
            if (resultFilters && resultFilters.length) {
                query.filter.and = resultFilters;
            } else {
                delete query.filter;
            }

            query.from = this.state.page * this.state.pageSize;
            query.size = this.state.pageSize;

            const sortField = this.state.sort || this.state.currentNavigationItem.resultSortField;
            const sortDirection = this.state.sortDirection || this.state.currentNavigationItem.resultSortDirection;

            if (sortField)
            {
                let sort = {};
                sort[sortField] = { order: sortDirection || "asc" };
                query.sort[0] =  sort  ;
            }

            this.setState({query: query, results: [], sort: sortField, sortDirection: sortDirection });
        }
    }

    private composeChildFilters(fieldsToFilter:{[key:string]: string}, navItem:IDocumentNavigationItem) {
        if (navItem && navItem.childDocumentNavigationItems && navItem.childDocumentNavigationItems.length) {
            navItem.childDocumentNavigationItems.map((childNavItem, i) => {
                this.composeChildFilters(fieldsToFilter, childNavItem);
            });
        }

        if (navItem.childPropertySystemName) {
            fieldsToFilter[navItem.childPropertySystemName] = null;
            let possibleChosenFilters = this.state.currentNavigationPath.filter((pathItem, i) => {
                return pathItem.childPropertySystemName === navItem.childPropertySystemName
            });
        }
    }

    private ensureUpdatedHash() {
        let somethingChanged = false;

        if (!this.hashParams) {
            this._hashParams = {};
        }

        SearchDocumentNavigator.hashToStateMap.map((hashToState, i) => {
            if (typeof(this.state[hashToState.stateParam]) !== 'undefined' && this.hashParams[hashToState.hashParam] !== this.state[hashToState.stateParam]) {
                this.hashParams[hashToState.hashParam] = this.state[hashToState.stateParam];
                somethingChanged = true;
            }
        });

        if (somethingChanged) {
            window.location.hash = $.param(this.hashParams);
        }
    }

    protected getPartialStateFromHash():any {
        let somethingChanged = false;
        let stateSetting:any = {};
        let currentState = this.state;

        if (location.hash && location.hash.length && location.hash.indexOf("#/") == 0) {
            this.handleLegacyHash();
        }

        if (this.hashParams) {
            SearchDocumentNavigator.hashToStateMap.map((hashToState, i) => {
                if (typeof(this.hashParams[hashToState.hashParam]) !== 'undefined' && this.hashParams[hashToState.hashParam] !== currentState[hashToState.stateParam]) {
                    if (typeof currentState[hashToState.stateParam] === 'number') {
                        stateSetting[hashToState.stateParam] = Number(this.hashParams[hashToState.hashParam]);
                    } else {
                        stateSetting[hashToState.stateParam] = this.hashParams[hashToState.hashParam];
                    }
                    somethingChanged = true;
                }
            });
        }
      
        return stateSetting;
    }

    private handleLegacyHash() {
        let legacyNavHash = location.hash.substring(1);
        location.hash = "nt=" + encodeURIComponent(legacyNavHash.replace(/\|/g,":"));
    }

    private parseNavigationPath(navigationPath:string):Array<{MenuKey:string,MenuValue:string}> {
        if (navigationPath && navigationPath.indexOf("/") >= 0 ) {
            let splitNavPath = navigationPath.match(/(?:[^\/]|\/\/)+/g);
            let navTrail:Array<{MenuKey:string,MenuValue:string}> = [];

            splitNavPath.map((navPathItem, i) => {
                if (navPathItem && navPathItem.indexOf(':') > 0) {
                    let splitKeyValue = navPathItem.match(/(?:[^\:]|::)+/g);

                    if (splitKeyValue && splitKeyValue.length === 2) {
                        navTrail.push({ MenuKey: splitKeyValue[0].replace(/\/\//g,"/").replace(/::/g,":"), MenuValue: splitKeyValue[1].replace(/\/\//g,"/").replace(/::/g,":")  });
                    }
                }
            });

            return navTrail;
        } else {
            return null;
        }
    }

    private parseParams(str) {
        if (str) {
            return str.split('&').reduce(function (params, param) {
                var paramSplit = param.split('=').map(function (value) {
                    return decodeURIComponent(value.replace('+', ' '));
                });
                params[paramSplit[0]] = paramSplit[1];
                return params;
            }, {});
        } else {
            return null;
        }
    }


	constructResultItem() : SearchNavigableDocumentItem {
		return new SearchNavigableDocumentItem();
	}

	public GenerateDocumentNavigatorQuery():SearchDocumentNavigatorQuery {
		var query = new SearchDocumentNavigatorQuery();

		return query;
    }
    
    public performAdditionnalSearchResultParsing(results: Array<SearchNavigableDocumentItem>, data:any) : any {
        if (data.facets && data.facets[this.state.currentNavigationItem.childPropertySystemName] && data.facets[this.state.currentNavigationItem.childPropertySystemName].terms) {
            let generatedChildOptions:Array<IDocumentNavigationItem> = [];

            if (this.state.currentNavigationItem.useDynamicChoiceMode === "DynamicChoices" 
                && this.state.currentNavigationItem.childDocumentNavigationItems
                && this.state.currentNavigationItem.childDocumentNavigationItems.length == 1
                && !this.state.currentNavigationItem.childDocumentNavigationItems[0].parentPropertyValue) {

                data.facets[this.state.currentNavigationItem.childPropertySystemName].terms.map((facetItem, facetIndex) => {
                    let generatedChild = { ... this.state.currentNavigationItem.childDocumentNavigationItems[0]};
                    generatedChild.parentPropertyValue = facetItem.term;
                    generatedChild.title = facetItem.term;
                    generatedChild.summary = null;
                    generatedChildOptions.push(generatedChild);
                })
            } else if (this.state.currentNavigationItem.useDynamicChoiceMode === "DynamicChoices"
                && (!this.state.currentNavigationItem.childDocumentNavigationItems
                || !this.state.currentNavigationItem.childDocumentNavigationItems.length)) {

                data.facets[this.state.currentNavigationItem.childPropertySystemName].terms.map((facetItem, facetIndex) => {
                    let generatedChild = { ... this.state.currentNavigationItem};
                    generatedChild.childPropertySystemName = null;
                    generatedChild.parentPropertyValue = facetItem.term;
                    generatedChild.title = facetItem.term;
                    generatedChild.summary = null;
                    generatedChild.useDynamicChoiceMode = "None";
                    generatedChildOptions.push(generatedChild);
                })
            }

            if (generatedChildOptions && generatedChildOptions.length > 0) {
                this.state.currentNavigationItem.childDocumentNavigationItems = generatedChildOptions;
            }
        } 

            if (this.state.forceLoading && this.hashParams && this.hashParams.nt) {
            let navTrail = this.parseNavigationPath(this.hashParams.nt);
            let desiredNavTrailLength = navTrail.length;
            
            if (desiredNavTrailLength <= this.state.navigationTrail.length) {
                return { forceLoading: false };
            } else {
                navTrail.splice(this.state.navigationTrail.length + 1);
                let updatedNavState = this.GetNextNavigationItemFromNavTrail(navTrail, this.state.currentNavigationItem) || {};
                
                if (desiredNavTrailLength === this.state.navigationTrail.length + 1) {
                    updatedNavState.forceLoading = false;
                }
                return updatedNavState;
            }
        } else if (this.state.currentNavigationItem.childDocumentNavigationItems && this.state.currentNavigationItem.childDocumentNavigationItems.length === 1
            && (!data.hits || !data.hits.total)) {
            let navTrail = this.state.navigationTrail;
            navTrail.push({ MenuKey:this.state.currentNavigationItem.childPropertySystemName,MenuValue:this.state.currentNavigationItem.childDocumentNavigationItems[0].parentPropertyValue });
            let updatedNavState = this.GetNextNavigationItemFromNavTrail(navTrail, this.state.currentNavigationItem);
            return updatedNavState;
        }
    }

    public getPropertyNameForNavigation() {
        if (this.state.currentNavigationItem.childPropertySystemName) {
            return this.state.currentNavigationItem.childPropertySystemName;
        } else if (this.state.currentNavigationPath.length > 1) {
            return this.state.currentNavigationPath[this.state.currentNavigationPath.length - 2].childPropertySystemName;
        }
    }

    public setSort(propertyName) {
        let sortField = this.state.sort || this.state.currentNavigationItem.resultSortField;
        let sortDirection = this.state.sortDirection || this.state.currentNavigationItem.resultSortDirection;

        if (sortField === propertyName) {
            if (sortDirection === "desc") {
                sortDirection = "asc";
            } else {
                sortDirection = "desc";
            }
        } else {
            sortField = propertyName;
            sortDirection = "asc";
        }
        
        this.setState({ page: 0, sort: sortField, sortDirection: sortDirection });
    }

    public renderReadersGuide() {
        return null;
    }
 
    public render() {

        if (!this.state.currentNavigationPath || !this.state.currentNavigationPath.length || !this.state.currentNavigationItem)
        return (<div>error with doc nav!</div>);

        let SearchDocumentResults = this.state.currentNavigationItem.displayResultInGrid && this.state.currentNavigationItem.gridMetadataColumns && this.state.currentNavigationItem.gridMetadataColumns.length ? SearchDocumentGrid : SearchDocumentList;

        return (       
            <div className="document-navigator">
                <div className="row">
                    <div className="col-12 col-lg-4 col-xl-3 docnav-menu">
                        <DocumentNavigatorMenu CurrentNavigationItem={this.state.currentNavigationItem} CurrentNavigationPath={this.state.currentNavigationPath} PathLength={this.state.currentNavigationPath.length} UseParameterizedHash={true} Loading={this.state.loading} ForceLoading={this.state.forceLoading} />
                    </div>
                    <div className="col-12 col-lg-8 col-xl-9">
                        <div className="row">
                            <div className="col-12">
                            <DocumentNavigatorTagCrumbs CurrentNavigationPath={this.state.currentNavigationPath} OnClickTabCrumb={this.clickedNavigationItemCrumb} UseParameterizedHash={true}/>
                            </div>
                            {this.renderReadersGuide()}
                            <div className="col-12">
                                <div className="docnav-navitem">                                    
                                    {this.state.currentNavigationItem && (this.state.currentNavigationItem.title || this.state.currentNavigationItem.parentPropertyValue) && <h3 className="margin-top-0">{this.state.currentNavigationItem.title || this.state.currentNavigationItem.parentPropertyValue}</h3> }
                                    {this.state.currentNavigationItem && this.state.currentNavigationItem.summary && <div className="docnav-summary" dangerouslySetInnerHTML={{__html: this.state.currentNavigationItem.summary }} /> }
                                    <SearchDocumentResults Results={this.state.results} Page={this.state.page} PageSize={this.state.pageSize} TotalAvailableResults={this.state.totalAvailableResults}
                                        disableFollowing={this.props.disableFollowing} Loading={ this.state.loading} ResultSetCount={this.state.results.length} Columns={this.state.currentNavigationItem.gridMetadataColumns} TableStyle={this.state.currentNavigationItem.tableStyle}
                                        NoResultsText={this.placeholderTemplate} IncludePagingDescription={true} ResultSortField={this.state.sort} ResultSortDirection={this.state.sortDirection}
                                        OnSortClick={(columnName) => this.setSort(columnName)} OnPageSizeChange={(pageSize) => this.setState({ page: 0, pageSize: pageSize })} OnPageChange={(pageIndex) => this.setState({ page: pageIndex })} UseDisplayTitle={this.state.currentNavigationItem.useDisplayTitle}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
