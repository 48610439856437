import React from 'react';
import Moment from "moment";
import {SearchNavigableDocumentItem} from './SearchNavigation';
import {IDocumentGridColumnDefinitionItem} from '../DocumentNavigator/DocumentNavigatorItem';
import {IPagingProps} from '../Find/Paging';
import Grid, {IGridProps} from '../../hocs/Grid';
import ListPager from '../../hocs/ListPager';
import * as dateFormatter from '../../dateFormatter';

interface ISearchDocumentGridProps extends IPagingProps, IGridProps {
    Results: Array<SearchNavigableDocumentItem>;
    disableFollowing: boolean; 
    UseDisplayTitle: boolean;
    TableStyle: string;
}

interface ISearchDocumentGridItemProps {
    Result:SearchNavigableDocumentItem;
    Loading:boolean;
    Columns:Array<IDocumentGridColumnDefinitionItem>;
    OpenDocumentsInNewTarget: boolean;
}

const renderMetadataColumn = function (result: SearchNavigableDocumentItem, col: IDocumentGridColumnDefinitionItem, colindex: number, openDocumentsInNewTarget?:boolean){
    var property = result[col.propertyName] || result.source[col.propertyName] || "";
    var propertyDate = null;

    var hasTime = property.indexOf("T00:00:00") == -1 ? true : false;
    var isUTC = property.indexOf("Z") != -1 ? true : false;

    if(isUTC){
        propertyDate = Moment.utc(property, Moment.ISO_8601, true);
    } else {
        propertyDate = Moment(property, Moment.ISO_8601, true);
    }
            
    if(propertyDate.isValid()){
        if(hasTime) {
            property = dateFormatter.DateFormatter.Format(propertyDate, "M/D/YYYY h:mm A");
        } else {
            property = dateFormatter.DateFormatter.Format(propertyDate, "M/D/YYYY");
        }            
    }        

    if(colindex == 0){
        return (
            <a href={result.SearchHitUrl.replace(" ", "%20")} rel="noopener noreferrer" target={ openDocumentsInNewTarget && openDocumentsInNewTarget === true ? "_blank" : "_self" }>{property}</a>
        )
    } else {
        return (
            property
        )
    }
}

const renderMobileMetadataContent = function (result: SearchNavigableDocumentItem, col: IDocumentGridColumnDefinitionItem, colindex: number) {
    var property = result[col.propertyName] || result.source[col.propertyName] || "";
    
    var propertyDate = Moment(property, Moment.ISO_8601, true);
    var hasTime = property.indexOf("T00:00:00") == -1 ? true : false;

    if(propertyDate.isValid()){
        if(hasTime) {
            property = dateFormatter.DateFormatter.Format(property, "M/D/YYYY h:mm A");
        } else {
            property = dateFormatter.DateFormatter.Format(propertyDate, "M/D/YYYY");
        }            
    }   

    if(colindex > 0){
        return(
            <div>
                <span className="dtr-title">{col.title}</span>
                <span className="dtr-data">{property}</span>
            </div>
        )
    }
}

const SearchDocumentGridItem: React.SFC<ISearchDocumentGridItemProps> = (props) => {
    return (
        <tbody>
            <tr className="desktop-row" role="row">
            {
                props.Columns.map((col, colindex) => {
                    return (
                        <td key={"col" + colindex} className={col.alignment ? "text-" + col.alignment : null }>
                            {renderMetadataColumn(props.Result, col, colindex, props.OpenDocumentsInNewTarget)}
                        </td>
                    )
                })
            }
            </tr>
            <tr className="mobile-row" role="row">
                <td><a href={props.Result.SearchHitUrl.replace(" ", "%20")}>{props.Result[props.Columns[0].propertyName] || props.Result.source[props.Columns[0].propertyName] || ""}</a></td>
            </tr>
            <tr className="mobile-row" role="row">
                <td>
                    <ul className="dtr-details">
                    {
                        props.Columns.map((col, colindex) => {
                            return (
                                <li key={colindex}>
                                    {renderMobileMetadataContent(props.Result, col, colindex)}
                                </li>
                            )
                        })
                    }
                    </ul>
                </td>
            </tr>
        </tbody>
    );
}

let listOptions = {
    className: "linkList",
    dataSetName: "Results",
    dataItemPropName: "Result"
};
export const SearchDocumentGrid = ListPager<ISearchDocumentGridProps>(Grid<ISearchDocumentGridProps>(SearchDocumentGridItem, listOptions), listOptions);

export default SearchDocumentGrid;