import React from 'react';
import DocumentNavigationItem, {IDocumentNavigationItem,IDocumentNavigatorFilter} from './DocumentNavigatorItem';
import DocumentNavigationMenu from './DocumentNavigatorMenu';
import DocumentNavigationTagCrumbs from './DocumentNavigatorTagCrumbs';


interface IDocumentNavigatorProps {    
    InitialFilters:Array<IDocumentNavigatorFilter>;
	DocumentNavigation:IDocumentNavigationItem;
    EnableFollowDocuments: boolean;
    OpenDocumentsInNewTarget: boolean;
}

interface IDocumentNavigatorState {
    currentNavigationPath:Array<IDocumentNavigationItem>;
    currentNavigationItem:IDocumentNavigationItem;    
    navigationQueryValues:{[key:string]: string};    
}

export class DocumentNavigator extends React.Component<IDocumentNavigatorProps, IDocumentNavigatorState>{
    private documentNavigationItemPanel:DocumentNavigationItem;

    constructor(props){
        super(props);
        this.state = { currentNavigationPath: [], currentNavigationItem: null, navigationQueryValues: {} };
        this.clickedNavigationItemCrumb = this.clickedNavigationItemCrumb.bind(this);
        this.clickedNavigationPropertyValue = this.clickedNavigationPropertyValue.bind(this);   
    }

      componentWillMount() {
        addEventListener("hashchange", this.handlePop.bind(this));
      }
    
      componentWillUnmount() {
        removeEventListener("hashchange", this.handlePop.bind(this));
      }
    
      private prevUrl:string;
      public handlePop(event:HashChangeEvent) {
          let numnewlevels = (event.newURL || location.href).split("|").length;
          let numoldlevels = (event.oldURL || this.prevUrl || "").split("|").length;
          this.prevUrl = location.href;
          if (numnewlevels >= numoldlevels) {
            let lastNav = location.hash.substring(location.hash.lastIndexOf("|") + 1);
            let slashspot = lastNav.indexOf("/");
            let parentPropertyValue = slashspot > 0 ? lastNav.substring(0, slashspot) : lastNav;
            let childPropertySystemName = slashspot > 0 ? lastNav.substring(slashspot + 1) : null;

            //console.log("whoo, caught it:"+ parentPropertyValue + "=" + childPropertySystemName);
            this.clickedNavigationPropertyValue(parentPropertyValue, childPropertySystemName);
          }
      }

    public componentDidMount() {
        if (this.props.DocumentNavigation) {
            let initialNavigationState = this.props.DocumentNavigation;
            if (!initialNavigationState.title && ! initialNavigationState.parentPropertyValue) {
                initialNavigationState.title = "Home";
            }
            let currentNavigationPath = this.state.currentNavigationPath || [];
            currentNavigationPath.push(initialNavigationState);

            let navigationQueryValues = this.state.navigationQueryValues || {};
            this.setState( { 
                currentNavigationPath: currentNavigationPath, 
                currentNavigationItem: initialNavigationState,
                navigationQueryValues: navigationQueryValues
            }, this.parseDrillDownFromHashPath.bind(this));
        }
    }
    
    async parseDrillDownFromHashPath() {
        let decodedHash = location.hash ? decodeURIComponent(location.hash) : null;
        if (decodedHash && decodedHash.indexOf("|") > 0 && decodedHash.indexOf("#/") === 0) {
            this.documentNavigationItemPanel.setLoadingState();
            let navigationQueryValues = this.state.navigationQueryValues;
            let currentNavigationPath = this.state.currentNavigationPath;
            let paths = decodedHash.substring(2).split('|');
            let autonavItem = this.state.currentNavigationItem;

            for (let pathLevel = 0; pathLevel < paths.length; pathLevel++) {
                if (pathLevel > 0 && autonavItem) {
                    let path = paths[pathLevel];
                    let slashspot = path.indexOf("/");
                    let propertyValue = slashspot > 0 ? path.substring(0, slashspot) : path;
                    let nextPropertySystemName = slashspot > 0 ? path.substring(slashspot + 1) : null;
                    let nextNavigationItem:IDocumentNavigationItem = null;

                    navigationQueryValues[autonavItem.childPropertySystemName] = propertyValue;
                    
                    if (autonavItem.childDocumentNavigationItems && autonavItem.childDocumentNavigationItems.length) {
                        for (var i = 0; i < autonavItem.childDocumentNavigationItems.length; i++) {
                            if (autonavItem.childDocumentNavigationItems[i].parentPropertyValue == propertyValue) {
                                nextNavigationItem = autonavItem.childDocumentNavigationItems[i];
                                break;
                            }
                        }
                    }
                    
                    if (nextNavigationItem) {
                        currentNavigationPath.push(nextNavigationItem);                                
                    
                        if (nextNavigationItem && nextNavigationItem.useDynamicChoiceMode && !(nextNavigationItem.childDocumentNavigationItems && nextNavigationItem.childDocumentNavigationItems.length > 0)) {
                            await this.getDynamicNavigationItems(navigationQueryValues, currentNavigationPath, nextNavigationItem, true);              
                        }

                        autonavItem = nextNavigationItem;
                    } else {
                        break;
                    }

                }
            }

            this.setState({ 
                currentNavigationPath: currentNavigationPath, 
                currentNavigationItem: autonavItem,
                navigationQueryValues: navigationQueryValues
            });
        }
    }

    private clickedNavigationItemCrumb(index:number) {
        var currentNavigationPath = this.state.currentNavigationPath || [];
        var navigationQueryValues = this.state.navigationQueryValues || {};
        var lastUnremovedNavigationItem = currentNavigationPath[currentNavigationPath.length - 1];

        if (index < currentNavigationPath.length - 1) {
            var removedNavagationItems = currentNavigationPath.splice(index + 1);
            lastUnremovedNavigationItem = currentNavigationPath[currentNavigationPath.length - 1];

            for (var i = 0; i < removedNavagationItems.length; i++) {
                if (navigationQueryValues[removedNavagationItems[i].childPropertySystemName]) {
                    delete navigationQueryValues[removedNavagationItems[i].childPropertySystemName];
                }
            }

            if (navigationQueryValues[lastUnremovedNavigationItem.childPropertySystemName]) {
                delete navigationQueryValues[lastUnremovedNavigationItem.childPropertySystemName];
            }
        }        
        
        this.setState({
            currentNavigationPath: currentNavigationPath,
            currentNavigationItem: currentNavigationPath[currentNavigationPath.length - 1],
            navigationQueryValues: navigationQueryValues
        });
    }

    private clickedNavigationPropertyValue(propertyValue:string, childPropertySystemName:string, suppressStateUpdate:boolean = false) {
        var navigationQueryValues = this.state.navigationQueryValues || {};
        var currentNavigationItem = this.state.currentNavigationItem;
        var currentNavigationPath = this.state.currentNavigationPath || [];
        var nextNavigationItem:IDocumentNavigationItem = null;

        if (currentNavigationItem) {
            if(currentNavigationItem.childPropertySystemName && currentNavigationItem.childPropertySystemName != childPropertySystemName){
                navigationQueryValues[currentNavigationItem.childPropertySystemName] = propertyValue;

                if (currentNavigationItem.childDocumentNavigationItems && currentNavigationItem.childDocumentNavigationItems.length) {
                    for (var i = 0; i < currentNavigationItem.childDocumentNavigationItems.length; i++) {
                        if (currentNavigationItem.childDocumentNavigationItems[i].parentPropertyValue == propertyValue) {
                            nextNavigationItem = currentNavigationItem.childDocumentNavigationItems[i];
                            break;
                        }
                    }
                }

                if (nextNavigationItem) {
                    currentNavigationPath.push(nextNavigationItem);
                }
            } else {
                // current item switches to sibling
                var currentNavDepth = currentNavigationPath.length;
                var parentNavigationItem = currentNavigationPath[currentNavDepth > 1 ? currentNavDepth - 2 : 0];

                navigationQueryValues[parentNavigationItem.childPropertySystemName] = propertyValue;
                
                for (var i = 0; i < parentNavigationItem.childDocumentNavigationItems.length; i++){
                    if(parentNavigationItem.childDocumentNavigationItems[i].parentPropertyValue == propertyValue){
                        nextNavigationItem = parentNavigationItem.childDocumentNavigationItems[i];
                        break;
                    }
                }

                // update nav path
                currentNavigationPath[currentNavigationPath.length - 1] = nextNavigationItem;
            }            
        }

        if (nextNavigationItem && nextNavigationItem.useDynamicChoiceMode && !(nextNavigationItem.childDocumentNavigationItems && nextNavigationItem.childDocumentNavigationItems.length > 0)) {
            this.documentNavigationItemPanel.setLoadingState();
            this.getDynamicNavigationItems(navigationQueryValues, currentNavigationPath, nextNavigationItem, suppressStateUpdate);              
        } else if (!suppressStateUpdate) {
            this.setState( { 
                navigationQueryValues: navigationQueryValues, 
                currentNavigationPath: currentNavigationPath, 
                currentNavigationItem: nextNavigationItem
            });
        }
    }

    private getDynamicNavigationItems(navigationQueryValues = this.state.navigationQueryValues, currentNavigationPath = this.state.currentNavigationPath, nextNavigationItem = this.state.currentNavigationItem, suppressStateUpdate:boolean = false) : Promise<void> {
        var promise1 = new Promise<void>((resolve, reject) => {
            var body = {
                QueryValues: navigationQueryValues,
                NavigationItem: nextNavigationItem,
                EnableFollowDocuments: this.props.EnableFollowDocuments
            };

            $.ajax({
                url: '/api/documents/getdynamicnavigationitems',
                type: 'POST',
                data: JSON.stringify(body),
                dataType: 'json',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                success: function (data) {
                    if (data) {

                        if (data.navigationItems) {
                            nextNavigationItem.childDocumentNavigationItems = data.navigationItems;
                        }

                        if (data.documents) {
                            nextNavigationItem.loadedNavigableDocuments = data.documents;
                            nextNavigationItem.totalAvailableResults = data.documents.length;
                        }

                        if (!suppressStateUpdate) {
                            this.setState( { 
                                navigationQueryValues: navigationQueryValues, 
                                currentNavigationPath: currentNavigationPath, 
                                currentNavigationItem: nextNavigationItem
                            });
                        }
                    } else {
                        this.documentNavigationItemPanel.setLoadingState(false);
                    }
                    resolve();
                }.bind(this),
                error: function (data) {
                    this.documentNavigationItemPanel.setLoadingState(false);
                    reject();
                }.bind(this)
            });
        });

        return promise1;
    }

    public render() {;
        if (!this.state.currentNavigationPath || !this.state.currentNavigationPath.length || !this.state.currentNavigationItem)
            return (<div></div>);
        return (
            <div className="document-navigator margin-top-20">
                <div className="row">
                    <div className="col-12 col-sm-3 docnav-menu">
                        <DocumentNavigationMenu CurrentNavigationItem={this.state.currentNavigationItem} CurrentNavigationPath={this.state.currentNavigationPath} PathLength={this.state.currentNavigationPath.length} Loading={false} />
                    </div>
                    <div className="col-12 col-sm-9">
                        <div className="row">
                            <div className="col-12">
                                <DocumentNavigationTagCrumbs CurrentNavigationPath={this.state.currentNavigationPath} OnClickTabCrumb={this.clickedNavigationItemCrumb} />
                            </div>
                            <div className="col-12">
                                <div className="docnav-navitem">
                                    <DocumentNavigationItem documentNavigationItem={this.state.currentNavigationItem} navigationQueryValues={this.state.navigationQueryValues} filters={this.props.InitialFilters} enableFollowDocuments={this.props.EnableFollowDocuments} openDocumentsInNewTarget={this.props.OpenDocumentsInNewTarget} ref={  instance => { this.documentNavigationItemPanel = instance } } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}