// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX
import React from 'react';
import Moment from "moment";
import * as dateFormatter from '../../dateFormatter';

interface IMCSNotificationProps { id: string, overviewLandingPageUrl: string }
interface IMCSNotification {
	notificationId : string,
	subject: string,
	body: string,
	publishDate: string,
	publishDateUnformatted: Date
}

interface State {
	notification: IMCSNotification
}

export class MCSNotification extends React.Component<IMCSNotificationProps, any> {

	constructor(props) {
		super(props);
		this.state = { notification: {} };
	}

	private getMCSNotification() {
		return $.getJSON('/api/topicnotifications/GetTopicNotification?id=' + this.props.id);
	}

	public componentDidMount() {
		$.when(this.getMCSNotification())
		 .done(function (n) {
			 this.setState({ notification: n });
		 }.bind(this));
	}
	public FormatDate(date: Date) {
		var tempDate = Moment.utc(date, Moment.ISO_8601, true);
		return dateFormatter.DateFormatter.Format(tempDate, "MM/DD/YYYY");
	}
	public render() {
		return (
			<div className="row">
				<div className="col-12 margin-top-25">
					<h1 className="hero blue">Notification</h1>
					<div className="notificationSubjectRow d-lg-flex align-items-lg-center">
						<h2 className="margin-btm-0 hero blue d-lg-inline">{this.state.notification.subject}</h2>
						<span className="notificationDate d-flex">
							<span className="margin-left-15 margin-right-3 d-none d-lg-block"> - </span><i>{this.FormatDate(this.state.notification.publishDateUnformatted)}</i>
						</span>
					</div>
					<div className='notificationText margin-top-15' dangerouslySetInnerHTML={{ __html: this.state.notification.body }} />	
				</div>
			</div>
		);
	}
	
}